import React from 'react';

const PenIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <g clipPath="url(#clip0_1265_7301)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1987 4.38518V3.28711H22.0495V4.40951C22.3335 10.5423 24.7506 14.6925 27.0804 17.3113C28.2504 18.6264 29.4016 19.5585 30.2551 20.159C30.6816 20.459 31.0325 20.6754 31.2727 20.8148C31.3927 20.8844 31.4849 20.9347 31.5449 20.9665C31.5748 20.9824 31.5967 20.9937 31.61 21.0004L31.6232 21.0071L31.6245 21.0077L32.3538 21.3628L26.9934 37.3557H15.2906L9.92969 21.3613L10.6606 21.0072L10.6616 21.0067L10.6755 20.9997C10.6887 20.993 10.7106 20.9818 10.7405 20.966C10.8005 20.9343 10.8927 20.8842 11.0127 20.8148C11.2529 20.6759 11.6038 20.4601 12.0301 20.1607C12.8835 19.5613 14.0339 18.6305 15.2019 17.3153C17.5276 14.6964 19.9365 10.541 20.1987 4.38518ZM12.1863 22.2703L16.6222 35.505H25.6618L30.098 22.2693C29.8474 22.1166 29.5399 21.9187 29.1902 21.6727C28.242 21.0056 26.9786 19.9813 25.6977 18.5415C23.9539 16.5814 22.1835 13.8566 21.1315 10.2346C20.0893 13.8599 18.3258 16.5848 16.5857 18.5442C15.3061 19.9851 14.0426 21.0088 13.0938 21.6752C12.7444 21.9206 12.4369 22.118 12.1863 22.2703Z"
          fill="#6EBD44"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5811 35.4785H29.7025V44.0258H12.5811V35.4785ZM14.4318 37.3292V42.1751H27.8518V37.3292H14.4318Z"
          fill="#6EBD44"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2168 20.2566V3.26025H22.0675V20.2566H20.2168Z"
          fill="#6EBD44"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.142 21.3073C20.86 21.3073 20.5843 21.3909 20.3497 21.5476C20.1152 21.7043 19.9324 21.927 19.8245 22.1876C19.7166 22.4482 19.6883 22.7349 19.7434 23.0115C19.7984 23.2881 19.9342 23.5422 20.1337 23.7417C20.3331 23.9411 20.5872 24.0769 20.8638 24.132C21.1404 24.187 21.4272 24.1588 21.6877 24.0508C21.9483 23.9429 22.171 23.7601 22.3277 23.5256C22.4844 23.2911 22.5681 23.0154 22.5681 22.7333C22.5681 22.3551 22.4178 21.9924 22.1504 21.725C21.8829 21.4575 21.5202 21.3073 21.142 21.3073ZM19.3215 20.0088C19.8604 19.6487 20.4939 19.4565 21.142 19.4565C22.0111 19.4565 22.8445 19.8018 23.459 20.4163C24.0735 21.0308 24.4188 21.8642 24.4188 22.7333C24.4188 23.3814 24.2266 24.0149 23.8666 24.5538C23.5065 25.0927 22.9948 25.5127 22.396 25.7607C21.7972 26.0087 21.1384 26.0736 20.5027 25.9471C19.8671 25.8207 19.2832 25.5086 18.825 25.0503C18.3667 24.5921 18.0546 24.0082 17.9282 23.3726C17.8018 22.737 17.8666 22.0781 18.1147 21.4793C18.3627 20.8806 18.7827 20.3688 19.3215 20.0088Z"
          fill="#6EBD44"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.858 2.53683C40.8583 2.53734 40.8587 2.53783 40.097 3.0634C39.3354 3.58897 39.3357 3.58943 39.336 3.58987L39.3366 3.59066L39.3371 3.59151C39.3348 3.58829 39.3296 3.58116 39.3216 3.57052C39.3055 3.54923 39.2782 3.51407 39.24 3.46822C39.1632 3.37621 39.0438 3.24307 38.8842 3.09327C38.5619 2.79098 38.0939 2.43806 37.4972 2.20578C36.3853 1.77303 34.5818 1.66239 32.0738 3.77161C29.1301 6.24716 26.2608 6.63059 24.1071 6.35386C23.0438 6.21724 22.1696 5.92237 21.5582 5.66028C21.2518 5.52893 21.0091 5.40483 20.839 5.31094C20.7538 5.26395 20.6866 5.22439 20.6385 5.1951C20.6144 5.18045 20.595 5.16835 20.5805 5.15915L20.5625 5.14758L20.5562 5.1435L20.5538 5.1419L20.5528 5.14122C20.5523 5.14091 20.5518 5.14061 21.0622 4.36874C21.5727 3.59687 21.5722 3.5966 21.5718 3.59633L21.5711 3.59587L21.5701 3.59517C21.5695 3.59479 21.5692 3.59461 21.5692 3.59462L21.5727 3.59687C21.5774 3.59981 21.5866 3.60558 21.6001 3.61384C21.6273 3.63037 21.6721 3.65683 21.7333 3.69061C21.8558 3.75824 22.0434 3.85465 22.2874 3.95926C22.7768 4.16908 23.484 4.40786 24.343 4.51822C26.0337 4.73547 28.3724 4.46624 30.8826 2.35519C33.8284 -0.122176 36.3343 -0.232814 38.1684 0.481085C39.0447 0.822128 39.7088 1.32919 40.1504 1.74355C40.3727 1.95208 40.5432 2.14144 40.6608 2.28234C40.7198 2.35295 40.7658 2.41185 40.7987 2.4555C40.8152 2.47734 40.8285 2.49541 40.8385 2.50926L40.851 2.52685L40.8554 2.53319L40.8572 2.53572L40.858 2.53683ZM39.3371 3.59151L39.3375 3.59195L39.3385 3.59334C39.3387 3.59374 39.3383 3.59312 39.3371 3.59151Z"
          fill="#6EBD44"
        />
      </g>
      <defs>
        <clipPath id="clip0_1265_7301">
          <rect
            width="43.8095"
            height="43.8095"
            fill="white"
            transform="translate(0.0957031 0.0952148)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PenIcon;
