import React from 'react';

const TelescopeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="43.179" height="43.185" viewBox="0 0 43.179 43.185">
            <g id="telescope" transform="translate(-4.755 -4.749)">
                <path id="Path_10883" data-name="Path 10883" d="M47.146,10.116A6.056,6.056,0,0,0,42.991,7.16l-.465-1.923a.638.638,0,0,0-.772-.469l-3.571.865a.638.638,0,0,0-.47.77l.34,1.406-23.7,5.738a.638.638,0,0,0-.468.769l.228.943L9.813,16.3a.638.638,0,0,0-.47.77l.337,1.392L5.243,19.538a.638.638,0,0,0-.47.77l.82,3.386a.638.638,0,0,0,.62.488.651.651,0,0,0,.151-.018L10.8,23.089l.336,1.388a.638.638,0,0,0,.62.488.632.632,0,0,0,.15-.018l4.3-1.042.229.944a.638.638,0,0,0,.62.488.632.632,0,0,0,.15-.018l6.374-1.544a2.814,2.814,0,0,0,.437,1.02L13.867,45.44a1.73,1.73,0,1,0,3.1,1.525l7.645-15.549V46.2a1.731,1.731,0,1,0,3.462,0V31.416l7.645,15.549a1.742,1.742,0,0,0,1.554.969,1.689,1.689,0,0,0,.761-.178,1.728,1.728,0,0,0,.791-2.316L28.678,24.793a2.8,2.8,0,0,0,.39-2.347L40.9,19.575l.341,1.407a.638.638,0,0,0,.77.47l3.571-.865a.637.637,0,0,0,.47-.77L45.592,17.9a6.066,6.066,0,0,0,1.554-7.78ZM6.683,22.773l-.52-2.146L9.98,19.7l.52,2.146Zm5.543.784-.332-1.373a.037.037,0,0,0,0-.015.121.121,0,0,1-.005-.015L11.073,18.8v-.013s0-.008,0-.013l-.334-1.379,3.684-.891,1.494,6.165Zm15.417-1.2a1.54,1.54,0,0,1-.2,1.934l0,0a1.547,1.547,0,0,1-2.189,0l0,0a1.547,1.547,0,1,1,2.393-1.936ZM15.826,46.4a.455.455,0,0,1-.838-.054A.449.449,0,0,1,15.011,46l9.606-19.538v2.06Zm10.521.255a.456.456,0,0,1-.456-.456V25.983a2.608,2.608,0,0,0,.911,0V46.2a.456.456,0,0,1-.456.456Zm11.359-.309a.454.454,0,0,1-.235.263.464.464,0,0,1-.6-.21l-8.79-17.876v-2.06L37.685,46a.447.447,0,0,1,.022.344ZM26.348,20.374a2.826,2.826,0,0,0-2.72,2.077l-6.1,1.477-2.021-8.345v-.006l-.228-.941L38.354,9.048l2.25,9.288L28.423,21.289a2.814,2.814,0,0,0-2.075-.915Zm15.987-.312L39.448,8.146a.046.046,0,0,0,0-.02.1.1,0,0,1-.006-.018L39.1,6.723l2.332-.565L44.667,19.5Zm.995-11.5A4.772,4.772,0,0,1,45.253,16.5Z" fill="#6ebd44"/>
                <path id="Path_10884" data-name="Path 10884" d="M67.609,23.9l-4.9,1.187a.638.638,0,1,0,.3,1.239l4.9-1.187a.638.638,0,1,0-.3-1.239Z" transform="translate(-36.514 -12.16)" fill="#6ebd44"/>
                <path id="Path_10885" data-name="Path 10885" d="M85.282,21.738a.636.636,0,0,0-.77-.47l-1.458.353a.638.638,0,1,0,.3,1.239l1.456-.353a.637.637,0,0,0,.472-.769Z" transform="translate(-49.443 -10.487)" fill="#6ebd44"/>
            </g>
        </svg>
    );
};

export default TelescopeIcon;
