import React from 'react';

const DesignToolsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="43.426" height="43.185" viewBox="0 0 43.426 43.185">
            <g id="design-tools" transform="translate(-59.619 -60.707)">
                <path id="Path_10886" dat-name="Path 10886"
                      d="M116.423,371.167a.663.663,0,0,0-.938.938l1.639,1.639a.663.663,0,1,0,.938-.938Z"
                      transform="translate(-49.517 -275.962)" fill="#6ebd44"/>
                <path id="Path_10887" dat-name="Path 10887"
                      d="M67.254,80.718a.663.663,0,1,0,.938-.938l-6.97-6.97,2.285-2.259,1.887,1.887,8.6,8.6L60.376,94.664a1.769,1.769,0,0,0,0,2.5l6.208,6.208a1.771,1.771,0,0,0,2.5,0L82.708,89.753l9.82,9.82a.655.655,0,0,0,.092.075l.027.018a.659.659,0,0,0,.089.047l.014.007a.675.675,0,0,0,.088.028l7.087,1.746a.663.663,0,0,0,.8-.8L98.983,93.6a.666.666,0,0,0-.028-.088l-.007-.014a.662.662,0,0,0-.047-.089l-.018-.027a.659.659,0,0,0-.075-.092l-9.82-9.82,13.54-13.539a1.769,1.769,0,0,0,0-2.5l-6.208-6.208a1.771,1.771,0,0,0-2.5,0l-3.559,3.559-9.98,9.98-10.95-10.95a.663.663,0,0,0-.938,0l-.325.325-.9-.913a3.3,3.3,0,0,0-2.366-.992h0a3.322,3.322,0,0,0-2.349,5.67l.92.92-3.554,3.515a.663.663,0,0,0,0,.941ZM97.4,93.763l-4.4,4.4-26.2-26.2,4.4-4.4Zm-29.252,8.674a.443.443,0,0,1-.626,0l-6.208-6.208a.442.442,0,0,1,0-.625L70.6,86.319l2.963,2.963a.663.663,0,1,0,.938-.938l-2.963-2.963,3.4-3.4,6.834,6.834Zm26.15-3.7,3.678-3.678,1.2,4.88ZM84.017,72.9l2.963,2.963a.663.663,0,1,0,.938-.938l-2.963-2.963,5.772-5.772,1.754,1.754a.663.663,0,1,0,.938-.938l-1.754-1.753,3.09-3.09a.443.443,0,0,1,.626,0l6.208,6.208a.443.443,0,0,1,0,.625L88.05,82.535,81.216,75.7ZM70.266,66.628l-4.4,4.4-1.407-1.407,4.4-4.4Zm-6.878.336a1.995,1.995,0,0,1,1.41-3.405h0a1.98,1.98,0,0,1,1.421.6l.907.921-2.69,2.689-.125.124Z"
                      fill="#6ebd44"/>
            </g>
        </svg>
    );
};

export default DesignToolsIcon;
