import React from 'react';
import ProcessCartWrapper, { CartDescription, CartNumber, CartTitle } from './processCard.styled';
import IconWithBackground from 'components/atoms/iconWithBackground';
import { ProcessCardTypes } from './types';

const ProcessCard = ({ data }: ProcessCardTypes) => {
  return (
    <ProcessCartWrapper>
      <CartNumber>{data.id}</CartNumber>
      <IconWithBackground>{data.image}</IconWithBackground>
      <CartTitle>{data.title}</CartTitle>
      <CartDescription>{data.description}</CartDescription>
    </ProcessCartWrapper>
  );
};

export default ProcessCard;
