import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyle from './styles/global';

import { Routes, Route, useLocation } from 'react-router-dom';

import Navbar from './components/organisms/navbar';

import HomePage from './pages/homePage';
import ProcessPage from './pages/processPage';
import Footer from './components/organisms/footer';
import ProductsPage from './pages/productsPage';
import AboutPage from './pages/aboutPage';
import ContactPage from './pages/contactPage';
import CareerPage from './pages/careerPage';
import ServicePage from './pages/servicesPage';
import CurrentJobPage from './pages/currentJobPage';
import SendCvPage from './pages/sendCvPage';
import ErrorPage from './pages/errorPage';

const App = () => {
  const location = useLocation();
  return (
    <div className="app">
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path={'/'} element={<HomePage />} />
        <Route path={'/services'} element={<ServicePage />} />
        <Route path={'/process'} element={<ProcessPage />} />
        <Route path={'/products'} element={<ProductsPage />} />
        <Route path={'/aboutUs'} element={<AboutPage />} />
        <Route path={'/career'} element={<CareerPage />} />
        <Route path={'/currentJob/:id'} element={<CurrentJobPage />} />
        <Route path={'/sendCv/:id'} element={<SendCvPage />} />
        <Route path={'/contactUs'} element={<ContactPage />} />
        <Route path={'/pageNotFound'} element={<ErrorPage />} />
        <Route path={'*'} element={<ErrorPage />} />
      </Routes>
      {location.pathname !== '/services' ? <Footer /> : ''}
    </div>
  );
};

export default App;
