import styled from 'styled-components';

const NoDataText = styled.div`
  text-align: center;
  color: var(--text-green);
  font-size: 20px;
  margin: 0 auto;
`;

export default NoDataText;
