import React from 'react';

const PaginationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="11.5" />
      <path d="M14.2915 6.29376C14.3842 6.38628 14.4578 6.49616 14.508 6.61714C14.5582 6.73811 14.584 6.86779 14.584 6.99876C14.584 7.12973 14.5582 7.25941 14.508 7.38039C14.4578 7.50136 14.3842 7.61125 14.2915 7.70376L10.4115 11.5838L14.2915 15.4638C14.4785 15.6507 14.5836 15.9043 14.5836 16.1688C14.5836 16.4332 14.4785 16.6868 14.2915 16.8738C14.1046 17.0607 13.851 17.1658 13.5865 17.1658C13.3221 17.1658 13.0685 17.0607 12.8815 16.8738L8.29154 12.2838C8.19884 12.1912 8.12529 12.0814 8.07511 11.9604C8.02493 11.8394 7.9991 11.7097 7.9991 11.5788C7.9991 11.4478 8.02493 11.3181 8.07511 11.1971C8.12529 11.0762 8.19884 10.9663 8.29154 10.8738L12.8815 6.28376C13.2615 5.90376 13.9015 5.90376 14.2915 6.29376Z" />
    </svg>
  );
};

export default PaginationIcon;
