import React from 'react';

const MobileNavbar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="375"
      height="80"
      viewBox="0 0 375 80"
      fill="none"
    >
      <g clipPath="url(#clip0_392_1746)">
        <path
          d="M126.462 57.4527V56.5501"
          stroke="black"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.4351 31.6303L53.3381 30.7258"
          stroke="black"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.5666 16.857C64.5811 16.619 68.6052 17.1174 72.4402 18.3277C76.8495 19.7918 82.0234 23.9264 84.5148 28.0423C88.4936 34.615 89.2825 37.4184 87.4173 44.0193C86.7397 46.4165 84.6251 49.1458 82.6815 52.4644C82.6815 52.4644 88.2941 47.1507 88.5849 38.7811C88.6553 36.7792 88.9503 35.7303 88.2974 33.3918C87.206 29.4844 85.0751 24.6422 80.9838 21.1502C79.0241 19.6798 76.8839 18.467 74.6156 17.5415C70.8637 16.058 65.7525 15.1535 60.5666 16.857Z"
          fill="url(#paint0_linear_392_1746)"
        />
        <path
          d="M45.9963 33.6668C49.0046 20.4988 60.6589 13.8886 70.7088 16.2218C81.8287 18.6983 90.715 29.5788 88.1928 41.5359C85.2321 53.8779 73.5302 64.1318 61.1496 59.1764C51.4367 55.2903 42.9861 46.8368 45.9963 33.6668Z"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.1765 17.2218C29.9006 13.984 43.024 19.2048 51.5932 22.6315L50.3613 24.2601C47.5106 23.2729 38.0942 20.7078 37.2891 21.9031C36.484 23.0984 48.2432 34.4754 62.8699 42.6834C75.0102 49.4958 93.9726 54.3969 94.8514 53.2624C95.7302 52.128 90.582 47.6938 87.1544 45.1197L87.8749 42.9215C95.1354 47.9553 106.423 56.7528 104.819 60.56C103.215 64.3673 79.5674 55.4852 61.7936 45.9113C35.68 31.8462 26.4516 20.4603 28.1765 17.2218Z"
          fill="white"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeMiterlimit="10"
        />
        <path
          d="M48.0028 27.6887L47.3007 29.6042C46.5837 29.5781 45.4853 29.5761 44.2253 29.6066C43.2388 28.7563 42.3405 27.9432 41.54 27.1894C43.8017 27.3393 46.015 27.522 48.0028 27.6887Z"
          fill="white"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeMiterlimit="10"
        />
        <path
          d="M24.0288 29.488C24.406 27.5548 28.634 26.9915 33.893 27.0001C34.9348 28.007 36.1246 29.0704 37.4626 30.1902C35.6355 30.4401 34.264 30.7968 34.1142 31.2949C33.8185 32.2646 40.9561 36.2374 50.9265 39.7508C54.1649 41.7643 57.7879 43.8561 61.7955 46.026C62.2808 46.2892 62.7707 46.548 63.2653 46.8091C35.1186 41.0281 23.336 33.0345 24.0288 29.488Z"
          fill="white"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeMiterlimit="10"
        />
        <path
          d="M64.3662 43.4936C64.4606 43.5151 64.5554 43.5365 64.6498 43.5568C78.2498 46.5139 97.8176 45.6461 98.3247 44.3056C98.8318 42.9651 92.6136 40.2309 88.5821 38.7721L88.6278 36.4607C97.044 39.146 110.41 44.2513 109.99 48.3608C109.659 51.6122 93.8337 51.3116 77.4377 49.0935C72.9558 47.5329 68.5881 45.6617 64.3662 43.4936Z"
          fill="white"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeMiterlimit="10"
        />
        <path
          d="M96.1641 39.9941C99.3015 41.5662 102.834 43.5297 105.316 46.0125C109.238 49.9396 101.395 48.763 99.1712 48.2376"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.917 45.0999C103.748 47.325 96.0334 47.4546 94.5948 47.5831C89.6272 47.9785 84.6561 47.1927 79.5586 47.1927"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.1929 42.8752C50.2699 42.6144 46.609 40.7813 43.0784 39.4724C38.7622 37.9046 33.9257 36.3326 30.5254 33.0624"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.795 28.6122C31.9643 28.6122 24.9035 29.2668 27.1246 32.5389"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.1401 29.7887C32.3568 30.0518 32.2253 31.0995 32.6185 31.8849"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.446 37.6414C48.0476 34.6301 44.2553 31.8834 40.855 28.8744C39.6774 27.8286 30.5259 18.2766 37.0635 19.0617"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.8697 22.3324C43.994 20.5015 40.1993 20.3688 36.9336 19.5861"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.5415 18.2771C35.3627 18.014 31.8344 17.2289 30.9185 18.1466C29.3489 19.5859 32.2253 22.5934 33.142 23.5088"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M96.8182 51.5107C97.8644 53.0827 99.9568 56.2245 97.2083 57.0079C94.0709 58.0561 89.4941 54.8921 82.6284 52.6787"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M102.309 57.2688C102.179 59.1035 100.347 58.8408 99.0386 58.5777"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M94.3331 58.3131C91.8495 57.0042 88.9731 56.2211 86.3569 55.1738C79.8204 52.6883 73.2811 49.8073 66.7446 47.3233"
          stroke="#18102B"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M174.462 5L174.761 7.03301L176.604 7.94041L174.761 8.8478L174.465 10.8808L173.028 9.41178L171.002 9.75912L171.957 7.94237L171 6.12365L173.029 6.47138L174.462 5Z"
          fill="#303030"
        />
        <path
          d="M49.8722 59L50.3749 62.4432L53.5031 63.984L50.3772 65.5228L49.8789 68.968L47.4405 66.4782L44.0035 67.0669L45.6243 63.9852L44 60.9027L47.4393 61.4914L49.8722 59Z"
          fill="#79C346"
        />
        <path
          d="M202.005 27.5788L205.482 27.6727L207.535 24.853L208.515 28.1974L211.823 29.2781L208.953 31.2555L208.945 34.744L206.187 32.619L202.874 33.6925L204.042 30.4032L202.005 27.5788Z"
          fill="#79C346"
        />
        <path
          d="M246.005 65.5788L249.482 65.6714L251.532 62.8526L252.515 66.1962L255.823 67.2785L252.951 69.2554L252.947 72.7433L250.188 70.6179L246.877 71.6922L248.042 68.4059L246.005 65.5788Z"
          fill="#303030"
        />
        <path
          d="M6.32374 62L6.69574 64.763L9 66L6.69658 67.2351L6.3287 70L4.53487 68.0017L2.0023 68.4742L3.19731 66.0003L2 63.5283L4.53254 64.0008L6.32374 62Z"
          fill="#303030"
        />
        <path
          d="M127.005 19.5812L130.481 19.6726L132.535 16.853L133.515 20.1986L136.822 21.2805L133.954 23.2547L133.945 26.7444L131.187 24.6178L127.875 25.6928L129.044 22.4047L127.005 19.5812Z"
          fill="#303030"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M195.891 68.8456C196.523 68.8456 197.035 69.3579 197.035 69.9899V70.8965C197.035 71.5285 196.523 72.0408 195.891 72.0408C195.259 72.0408 194.747 71.5285 194.747 70.8965V69.9899C194.747 69.3579 195.259 68.8456 195.891 68.8456Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M130.877 55.5988C131.057 55.1807 130.977 54.6768 130.635 54.3351C130.188 53.8883 129.464 53.8883 129.017 54.3352C129.009 54.3431 129.001 54.3511 128.993 54.359C128.786 54.5656 128.596 54.7562 128.459 54.9096C128.389 54.9886 128.3 55.0945 128.223 55.2145C128.167 55.3014 128 55.57 128 55.9397C128 56.1564 128.058 56.3901 128.197 56.6029C128.329 56.8054 128.496 56.9295 128.626 57.0027C128.856 57.1321 129.083 57.1633 129.173 57.1746C129.36 57.198 129.597 57.1978 129.782 57.1976C129.797 57.1976 129.812 57.1976 129.826 57.1976C130.458 57.1976 130.97 56.6853 130.97 56.0533C130.97 55.8918 130.937 55.7381 130.877 55.5988Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M224.333 15.3371C224.779 14.8891 225.504 14.8874 225.952 15.3332C225.988 15.3697 226.022 15.4036 226.054 15.4353C226.086 15.4039 226.119 15.3703 226.156 15.3343C226.603 14.8879 227.327 14.8887 227.774 15.336C228.22 15.7834 228.22 16.508 227.772 16.9544C227.761 16.9653 227.75 16.9762 227.739 16.9872C227.541 17.1848 227.331 17.3948 227.138 17.5487C226.941 17.7062 226.569 17.9686 226.055 17.9686C225.542 17.9686 225.17 17.7066 224.972 17.5494C224.779 17.3957 224.568 17.1858 224.37 16.9882C224.359 16.9772 224.348 16.9663 224.337 16.9554C223.889 16.5096 223.887 15.785 224.333 15.3371ZM225.693 15.7734C225.693 15.7734 225.693 15.7731 225.694 15.7725C225.693 15.7731 225.693 15.7734 225.693 15.7734ZM226.415 15.772C226.416 15.7725 226.416 15.7728 226.416 15.7728C226.416 15.7728 226.416 15.7726 226.415 15.772Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.9492 55.6568C69.549 55.8576 68.8761 56.1952 68.1443 56.1952C67.5123 56.1952 67 55.6829 67 55.0509C67 54.4189 67.5123 53.9066 68.1443 53.9066C68.2771 53.9066 68.477 53.8331 68.9984 53.5733C69.0204 53.5624 69.0435 53.5508 69.0675 53.5387C69.4681 53.3377 70.1412 53 70.8737 53C71.5057 53 72.0181 53.5123 72.0181 54.1443C72.0181 54.7763 71.5057 55.2887 70.8737 55.2887C70.7402 55.2887 70.5398 55.3624 70.0188 55.6219C69.9966 55.633 69.9734 55.6446 69.9492 55.6568Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.8652 8.33603C70.3117 8.78339 70.3109 9.50794 69.8635 9.95436L68.9526 10.8633C68.5053 11.3097 67.7807 11.309 67.3343 10.8616C66.8879 10.4142 66.8887 9.68968 67.336 9.24327L68.2469 8.33431C68.6943 7.88789 69.4188 7.88866 69.8652 8.33603Z"
          fill="#18102B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M293.684 13.3358C294.13 13.7831 294.13 14.5077 293.682 14.9542C293.095 15.5402 292.5 15.8366 292.048 16.062C292.039 16.0666 292.03 16.0712 292.021 16.0758C291.566 16.3025 291.263 16.4599 290.952 16.7698C290.504 17.2157 289.779 17.214 289.333 16.766C288.887 16.3181 288.889 15.5935 289.337 15.1477C289.924 14.5634 290.518 14.2673 290.97 14.0423C290.98 14.0374 290.99 14.0325 290.999 14.0276C291.454 13.8011 291.755 13.6439 292.065 13.3345C292.513 12.888 293.237 12.8886 293.684 13.3358Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.13269 28.0575C8.64988 27.6944 9.36358 27.8193 9.72677 28.3366C10.2034 29.0154 10.3935 29.6524 10.5381 30.1367C10.541 30.1466 10.544 30.1565 10.5469 30.1663C10.6924 30.6534 10.7956 30.9788 11.0475 31.3389C11.4099 31.8567 11.284 32.5702 10.7663 32.9326C10.2485 33.2949 9.53503 33.1689 9.17262 32.6511C8.69771 31.9725 8.50779 31.3362 8.36349 30.8527C8.36033 30.8421 8.35719 30.8316 8.35407 30.8211C8.20874 30.3346 8.10556 30.0101 7.85385 29.6516C7.49065 29.1344 7.61549 28.4206 8.13269 28.0575Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M298.863 69.336C299.309 69.7834 299.309 70.508 298.861 70.9544L297.953 71.861C297.505 72.3074 296.781 72.3066 296.334 71.8592C295.888 71.4119 295.889 70.6873 296.336 70.2409L297.245 69.3343C297.692 68.8879 298.417 68.8887 298.863 69.336Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M186.351 65.84C186.355 66.4719 185.846 66.9879 185.215 66.9924L185.049 66.9935C184.417 66.998 183.901 66.4893 183.897 65.8574C183.892 65.2254 184.401 64.7094 185.033 64.7049L185.198 64.7038C185.83 64.6993 186.346 65.208 186.351 65.84Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M324.333 56.3373C324.779 55.8892 325.503 55.8873 325.951 56.3331C326.282 56.662 326.464 56.68 326.511 56.68C326.557 56.68 326.738 56.6624 327.067 56.3341C327.514 55.8878 328.239 55.8887 328.685 56.3362C329.132 56.7837 329.131 57.5082 328.683 57.9545C328.103 58.533 327.375 58.9687 326.511 58.9687C325.647 58.9687 324.918 58.5333 324.337 57.9556C323.889 57.5098 323.887 56.7853 324.333 56.3373Z"
          fill="black"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_392_1746"
          x1="79.2514"
          y1="19.6679"
          x2="67.2911"
          y2="45.2765"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92CF4B" />
          <stop offset="1" stopColor="#6EBD44" />
        </linearGradient>
        <clipPath id="clip0_392_1746">
          <rect width="375" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MobileNavbar;
