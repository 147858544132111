import React from 'react';

const PhoneRingIcon = () => {
    return (
        <svg id="call_2_" data-name="call (2)" xmlns="http://www.w3.org/2000/svg" width="49.309" height="49.306" viewBox="0 0 49.309 49.306">
            <g id="Group_3745" data-name="Group 3745" transform="translate(0 0)">
                <g id="Group_3744" data-name="Group 3744" transform="translate(0 0)">
                    <path id="Path_10895" data-name="Path 10895" d="M49.016,39.056,39.035,32.4a2.913,2.913,0,0,0-3.9.634l-2.907,3.738a1.242,1.242,0,0,1-1.593.335l-.553-.3c-1.833-1-4.114-2.243-8.71-6.841s-5.842-6.88-6.842-8.71l-.3-.553a1.244,1.244,0,0,1,.329-1.6l3.735-2.906a2.915,2.915,0,0,0,.634-3.9l-6.656-9.98a2.9,2.9,0,0,0-3.906-.879L4.19,3.939A5.887,5.887,0,0,0,1.505,7.423C0,12.9,1.133,22.35,15.054,36.272,26.128,47.346,34.372,50.323,40.039,50.323a14.558,14.558,0,0,0,3.862-.5,5.881,5.881,0,0,0,3.484-2.684l2.509-4.171A2.9,2.9,0,0,0,49.016,39.056Zm-.537,3.065-2.5,4.173a4.247,4.247,0,0,1-2.506,1.943c-5.055,1.388-13.887.238-27.252-13.127S1.7,12.914,3.09,7.858A4.253,4.253,0,0,1,5.035,5.349l4.173-2.5a1.257,1.257,0,0,1,1.7.381l3.616,5.428,3.035,4.551a1.265,1.265,0,0,1-.274,1.693L13.543,17.8a2.876,2.876,0,0,0-.762,3.691l.3.539c1.05,1.926,2.356,4.322,7.122,9.088s7.162,6.072,9.087,7.122l.54.3a2.876,2.876,0,0,0,3.691-.762l2.906-3.736a1.265,1.265,0,0,1,1.693-.274l9.98,6.656A1.256,1.256,0,0,1,48.478,42.12Z" transform="translate(-1 -1.018)" fill="#6ebd44"/>
                    <path id="Path_10896" data-name="Path 10896" d="M283.45,70.967a13.985,13.985,0,0,1,13.969,13.969.822.822,0,0,0,1.643,0A15.629,15.629,0,0,0,283.45,69.324a.822.822,0,0,0,0,1.643Z" transform="translate(-255.509 -62.747)" fill="#6ebd44"/>
                    <path id="Path_10897" data-name="Path 10897" d="M283.45,122.167a9.049,9.049,0,0,1,9.039,9.039.822.822,0,0,0,1.643,0,10.694,10.694,0,0,0-10.682-10.682.822.822,0,0,0,0,1.643Z" transform="translate(-255.509 -109.016)" fill="#6ebd44"/>
                    <path id="Path_10898" data-name="Path 10898" d="M283.45,173.367a4.114,4.114,0,0,1,4.109,4.109.822.822,0,1,0,1.643,0,5.758,5.758,0,0,0-5.752-5.752.822.822,0,0,0,0,1.643Z" transform="translate(-255.509 -155.286)" fill="#6ebd44"/>
                </g>
            </g>
        </svg>
    );
};

export default PhoneRingIcon;
