import React from 'react';
import Wrapper, { Header, Title } from './whyUsCart.styled';
import IconWithBackground from 'components/atoms/iconWithBackground';
import { WhyUsCartProps } from './types';

const WhyUsCart = ({ cartData }: WhyUsCartProps) => {
  if (!cartData) {
    return <h2>No Data</h2>;
  }
  return (
    <Wrapper>
      <Header>
        <IconWithBackground>{cartData.icon}</IconWithBackground>
        <Title>{cartData.title}</Title>
      </Header>
      <h5>{cartData.body}</h5>
    </Wrapper>
  );
};

export default WhyUsCart;
