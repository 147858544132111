import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Tab from 'react-bootstrap/Tab';
import ServicePageWrapper, { StyledTabs } from './servicePage.styled';
import Container from 'components/atoms/container';
import TextWithImage from 'components/molecules/textWithImage';

import { servicesData } from './mock';

const ServicePage = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState<number | string>('1');

  const handleSelect = (e: string | number) => {
    setKey(e);
  };

  return (
    <ServicePageWrapper>
      <Container>
        <StyledTabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(e: any) => handleSelect(e)}
        >
          {servicesData.map(data => {
            return (
              <Tab eventKey={data.id} title={data.title} key={data.id}>
                <TextWithImage data={data} onClick={() => navigate('/products')} />
              </Tab>
            );
          })}
        </StyledTabs>
      </Container>
    </ServicePageWrapper>
  );
};

export default ServicePage;
