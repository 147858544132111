import axios from 'axios';

export const getAllProducts = async () => {
  return await axios.get(`${process.env.REACT_APP_BASE_API_URL}/products`);
};

export const addProduct = async body => {
  return await axios.post(`${process.env.REACT_APP_BASE_API_URL}/products/add`, body);
};

export const getAllCareers = async ({ limit = 8, page = 1, type = '', search = '' }) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/career?limit=${limit}&page=${page}&type=${type}&search=${search}`,
  );
};

export const getCareerById = async (id = '') => {
  return await axios.get(`${process.env.REACT_APP_BASE_API_URL}/career/${id}`);
};

export const addCareer = async body => {
  return await axios.post(`${process.env.REACT_APP_BASE_API_URL}/career/add`, body);
};

export const sendCv = async body => {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_API_URL}/sendCv`,
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const contactUs = async body => {
  return await axios({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_API_URL}/contactUs`,
    data: body,
    headers: { 'Content-Type': 'application/json' },
  });
};
