import React from 'react';

const IdeaIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32.39" height="43.185" viewBox="0 0 32.39 43.185">
            <g id="idea" transform="translate(-9.998 -2)">
                <path id="Path_10874" data-name="Path 10874" d="M17.408,54.5a.72.72,0,0,0,1.018,0L21.3,51.624a.72.72,0,0,0,0-1.018l-.21-.211,2.159-2.153h9.058a.72.72,0,0,0,.509-.211l9.357-9.357a.72.72,0,0,0,0-1.018,4.8,4.8,0,0,0-6.776,0l-2.668,2.669H30.152V38.174a2.17,2.17,0,0,0-2.165-2.167L20.044,36a.72.72,0,0,0-.511.216l-4.286,4.324a.72.72,0,0,0-.209.5V42.91l-.72.715-.212-.216a.72.72,0,0,0-1.018,0l-2.879,2.879a.72.72,0,0,0,0,1.018ZM16.261,43.715a.72.72,0,0,0,.216-.51V41.342l3.866-3.9,7.643.006a.728.728,0,0,1,.727.728v2.152H22.955v1.439H33.031a.72.72,0,0,0,.509-.211l2.879-2.879a3.359,3.359,0,0,1,4.175-.453L32.014,46.8H22.955a.72.72,0,0,0-.508.21l-2.371,2.364-4.739-4.741ZM13.6,44.939l6.18,6.176-1.861,1.861-4.02-4.02.931-.931-1.018-1.018-.931.931L11.737,46.8Z" transform="translate(0 -9.529)" fill="#6ebd44"/>
                <path id="Path_10875" data-name="Path 10875" d="M31.989,2h1.439V3.439H31.989Z" transform="translate(-6.163)" fill="#6ebd44"/>
                <path id="Path_10876" data-name="Path 10876" d="M0,0H1.439V1.439H0Z" transform="translate(22.304 2.985) rotate(-22.514)" fill="#6ebd44"/>
                <path id="Path_10877" data-name="Path 10877" d="M0,0H1.439V1.439H0Z" transform="translate(19.418 5.251) rotate(-45)" fill="#6ebd44"/>
                <path id="Path_10878" data-name="Path 10878" d="M0,0H1.439V1.439H0Z" transform="translate(17.631 8.439) rotate(-67.486)" fill="#6ebd44"/>
                <path id="Path_10879" data-name="Path 10879" d="M0,0H1.439V1.439H0Z" transform="translate(33.592 7.658) rotate(-22.514)" fill="#6ebd44"/>
                <path id="Path_10880" data-name="Path 10880" d="M0,0H1.439V1.439H0Z" transform="translate(31.632 5.252) rotate(-45)" fill="#6ebd44"/>
                <path id="Path_10881" data-name="Path 10881" d="M0,0H1.439V1.439H0Z" transform="translate(28.917 3.763) rotate(-67.486)" fill="#6ebd44"/>
                <path id="Path_10882" data-name="Path 10882" d="M26.868,17.855v1.23a2.138,2.138,0,0,0-.877,3.466,2.149,2.149,0,0,0,1.6,3.6h5.758a2.15,2.15,0,0,0,1.6-3.6,2.139,2.139,0,0,0-.878-3.466v-1.23a6.478,6.478,0,1,0-7.2,0Zm6.478,6.856H27.588a.72.72,0,0,1,0-1.439h5.758a.72.72,0,1,1,0,1.439Zm0-2.879H27.588a.72.72,0,1,1,0-1.439h5.758a.72.72,0,1,1,0,1.439ZM30.467,7.437a5.038,5.038,0,0,1,2.519,9.4.72.72,0,0,0-.36.623v1.5H31.186V16.372L33.5,14.065a2.246,2.246,0,0,0,0-3.178,2.3,2.3,0,0,0-3.028-.137,2.3,2.3,0,0,0-3.028.137,2.249,2.249,0,0,0,0,3.177l.36.36,1.018-1.018-.36-.36a.809.809,0,0,1,0-1.141.826.826,0,0,1,1.142,0l.36.36a.72.72,0,0,0,1.018,0l.36-.36a.826.826,0,0,1,1.142,0,.805.805,0,0,1,0,1.141l-2.519,2.519a.72.72,0,0,0-.211.509v2.879H28.31v-1.5a.72.72,0,0,0-.36-.623,5.038,5.038,0,0,1,2.516-9.4Z" transform="translate(-3.921 -1.119)" fill="#6ebd44"/>
            </g>
        </svg>

    );
};

export default IdeaIcon;
