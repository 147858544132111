const slide1 = require('assets/photos/gallerySliderPhotos/slide1.png');
const slide2 = require('assets/photos/gallerySliderPhotos/slide2.png');
const slide3 = require('assets/photos/gallerySliderPhotos/slide3.png');
const slide4 = require('assets/photos/gallerySliderPhotos/slide4.png');
const slide5 = require('assets/photos/gallerySliderPhotos/slide5.png');
const slide6 = require('assets/photos/gallerySliderPhotos/slide6.png');
const slide7 = require('assets/photos/gallerySliderPhotos/slide7.png');
const slide8 = require('assets/photos/gallerySliderPhotos/slide8.png');

export const gallerySliderData = [
  {
    id: 1,
    imageUrl: slide1,
  },
  {
    id: 2,
    imageUrl: slide2,
  },
  {
    id: 3,
    imageUrl: slide3,
  },
  {
    id: 4,
    imageUrl: slide4,
  },
  {
    id: 5,
    imageUrl: slide5,
  },
  {
    id: 6,
    imageUrl: slide6,
  },
  {
    id: 7,
    imageUrl: slide7,
  },
  {
    id: 8,
    imageUrl: slide8,
  },
];
