const user1 = require('assets/photos/userPhotos/user1.png');
const user2 = require('assets/photos/userPhotos/user2.jpeg');
const user3 = require('assets/photos/userPhotos/user3.png');
const user4 = require('assets/photos/userPhotos/user4.png');

export const sliderItems = [
  {
    id: 1,
    userImage: user1,
    userName: 'Siim Tiigimagi',
    userComment:
      'Iguan Systems has become a permanent destination for me every time I come up with a new idea for my business.',
  },
  {
    id: 2,
    userImage: user2,
    userName: 'Todd Beuckens',
    userComment:
      'Iguan Systems is not just an IT company, You get the feeling of one big family when you start getting to know them. I absolutely love the atmosphere we have been working in for the last 5 months.',
  },
  {
    id: 3,
    userImage: user3,
    userName: 'Robert Schmidt',
    userComment:
      'Iguan Systems has enabled me to clearly visualize my business idea and I am happy to say that I have been able to realize my annual plan due to their precise calculation, efficient work plan and clean end result.',
  },
  {
    id: 4,
    userImage: user4,
    userName: 'Andre Norman',
    userComment:
      'As an experienced IT specialist who has been in the field for over 8 years now, I rate Iguan Systems 10/10 in terms of quality, cost-efficient and superfast turnaround of my project.',
  },
];
