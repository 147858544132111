import React from 'react';

const DocumentIcon = () => {
    return (
        <svg id="document_1_" data-name="document (1)" xmlns="http://www.w3.org/2000/svg" width="50.071" height="49.306"
             viewBox="0 0 50.071 49.306">
            <path id="Path_10907" data-name="Path 10907"
                  d="M45,.055c-.056,0-.111,0-.166,0a.717.717,0,0,0-.08,0H12.873A5.708,5.708,0,0,0,7.184,5.688l-.25,30.5H.77a.77.77,0,0,0-.77.77v4.571a7.775,7.775,0,0,0,7.192,7.812.761.761,0,0,0,.161.017h26a.76.76,0,0,0,.107-.008c.107,0,.214.008.322.008a7.768,7.768,0,0,0,7.654-7.234c0-.018,0-.036,0-.054l.013-3.43.007-17.178V15.024H49.3a.77.77,0,0,0,.77-.77V5.129A5.08,5.08,0,0,0,45,.055ZM7.676,47.821a6.221,6.221,0,0,1-6.137-6.29v-3.8H26.106v3.8a7.87,7.87,0,0,0,3.111,6.29ZM39.923,21.465l-.007,17.174-.013,3.4a6.135,6.135,0,0,1-12.258-.507V36.96a.769.769,0,0,0-.77-.77H8.474L8.724,5.7a4.163,4.163,0,0,1,4.149-4.107H41.362a5.057,5.057,0,0,0-1.438,3.535Zm8.608-7.98H41.462V5.129a3.534,3.534,0,1,1,7.069,0Zm0,0"
                  transform="translate(0 -0.055)" fill="#6ebd44"/>
            <path id="Path_10908" data-name="Path 10908"
                  d="M250.444,80.488h-.679a.77.77,0,1,0,0,1.54h.679a.77.77,0,0,0,0-1.54Zm0,0"
                  transform="translate(-217.047 -70.168)" fill="#6ebd44"/>
            <path id="Path_10909" data-name="Path 10909"
                  d="M205.531,81.258a.77.77,0,0,0,.77.77h2.709a.77.77,0,0,0,0-1.54H206.3A.77.77,0,0,0,205.531,81.258Zm0,0"
                  transform="translate(-179.159 -70.168)" fill="#6ebd44"/>
            <path id="Path_10910" data-name="Path 10910"
                  d="M113.43,82.028h9.283a.77.77,0,1,0,0-1.54H113.43a.77.77,0,0,0,0,1.54Zm0,0"
                  transform="translate(-98.205 -70.168)" fill="#6ebd44"/>
            <path id="Path_10911" data-name="Path 10911"
                  d="M233.011,40.488h-3.245a.77.77,0,1,0,0,1.54h3.245a.77.77,0,1,0,0-1.54Zm0,0"
                  transform="translate(-199.614 -35.3)" fill="#6ebd44"/>
            <path id="Path_10912" data-name="Path 10912"
                  d="M146.3,42.028h7.841a.77.77,0,0,0,0-1.54H146.3a.77.77,0,1,0,0,1.54Zm0,0"
                  transform="translate(-126.858 -35.3)" fill="#6ebd44"/>
            <path id="Path_10913" data-name="Path 10913"
                  d="M113.43,42.028h1.585a.77.77,0,1,0,0-1.54H113.43a.77.77,0,1,0,0,1.54Zm0,0"
                  transform="translate(-98.205 -35.3)" fill="#6ebd44"/>
            <path id="Path_10914" data-name="Path 10914"
                  d="M151.538,120.488H136.3a.77.77,0,0,0,0,1.54h15.237a.77.77,0,1,0,0-1.54Zm0,0"
                  transform="translate(-118.141 -105.035)" fill="#6ebd44"/>
            <path id="Path_10915" data-name="Path 10915"
                  d="M113.43,122.028h.3a.77.77,0,1,0,0-1.54h-.3a.77.77,0,1,0,0,1.54Zm0,0"
                  transform="translate(-98.205 -105.035)" fill="#6ebd44"/>
            <path id="Path_10916" data-name="Path 10916"
                  d="M250.444,200.488h-.679a.77.77,0,1,0,0,1.54h.679a.77.77,0,0,0,0-1.54Zm0,0"
                  transform="translate(-217.047 -174.771)" fill="#6ebd44"/>
            <path id="Path_10917" data-name="Path 10917"
                  d="M209.78,201.258a.77.77,0,0,0-.77-.77H206.3a.77.77,0,1,0,0,1.54h2.709A.77.77,0,0,0,209.78,201.258Zm0,0"
                  transform="translate(-179.16 -174.771)" fill="#6ebd44"/>
            <path id="Path_10918" data-name="Path 10918"
                  d="M113.43,202.028h9.283a.77.77,0,1,0,0-1.54H113.43a.77.77,0,1,0,0,1.54Zm0,0"
                  transform="translate(-98.205 -174.771)" fill="#6ebd44"/>
            <path id="Path_10919" data-name="Path 10919"
                  d="M233.011,160.488h-3.245a.77.77,0,1,0,0,1.54h3.245a.77.77,0,1,0,0-1.54Zm0,0"
                  transform="translate(-199.614 -139.903)" fill="#6ebd44"/>
            <path id="Path_10920" data-name="Path 10920"
                  d="M154.142,162.028a.77.77,0,1,0,0-1.54H146.3a.77.77,0,0,0,0,1.54Zm0,0"
                  transform="translate(-126.859 -139.903)" fill="#6ebd44"/>
            <path id="Path_10921" data-name="Path 10921"
                  d="M113.43,162.028h1.585a.77.77,0,1,0,0-1.54H113.43a.77.77,0,1,0,0,1.54Zm0,0"
                  transform="translate(-98.205 -139.903)" fill="#6ebd44"/>
            <path id="Path_10922" data-name="Path 10922"
                  d="M238.707,240.488H236.3a.77.77,0,0,0,0,1.54h2.406a.77.77,0,1,0,0-1.54Zm0,0"
                  transform="translate(-205.31 -209.638)" fill="#6ebd44"/>
            <path id="Path_10923" data-name="Path 10923"
                  d="M126.562,240.488H113.43a.77.77,0,0,0,0,1.54h13.132a.77.77,0,0,0,0-1.54Zm0,0"
                  transform="translate(-98.205 -209.638)" fill="#6ebd44"/>
        </svg>

    );
};

export default DocumentIcon;
