import React from 'react';
import InputStyled from './input.styled';
import { IInputProps } from './types';

const Input = ({
  type,
  placeholder,
  className,
  value,
  onChange,
  validated,
  error,
}: IInputProps) => {
  return (
    <InputStyled
      type={type}
      placeholder={placeholder}
      className={className}
      value={value}
      onChange={onChange}
      validated={validated}
      hasError={error}
    />
  );
};

export default Input;
