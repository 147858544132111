import styled, { css } from 'styled-components';
import { TextAreaStyledProps } from './types';

const TextareaStyled = styled.textarea<TextAreaStyledProps>`
  width: 100%;
  border: 1px solid #8d8d8d;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  padding: 10px 17px;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  margin-bottom: 20px;
  resize: none;
  &:focus {
    border-color: var(--primary-darker);
    filter: drop-shadow(0px 0.5px 4px var(--primary-darker));
    outline: none;
    color: var(--text-dark);
    ${({ hasError, validated }) =>
      hasError &&
      !validated &&
      css`
        border-color: var(--color-error);
        filter: drop-shadow(0px 0.5px 4px var(--color-error));
        ::placeholder {
          color: var(--color-error-lighter);
        }
        color: var(--color-error-lighter);
      `}
  }
  ${({ hasError }) =>
    hasError &&
    css`
      border-color: var(--color-error);
      filter: drop-shadow(0px 0.5px 4px var(--color-error));
      ::placeholder {
        color: var(--color-error-lighter);
      }
      color: var(--color-error-lighter);
    `}
  ${({ validated }) =>
    validated &&
    css`
      color: var(--color-success);
      border-color: var(--color-success);
      filter: drop-shadow(0px 0.5px 4px var(--color-success));
      ::placeholder {
        color: var(--color-success);
      }
    `}
`;

export default TextareaStyled;
