import React from 'react';

const DirectionalSignIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33.989" height="43.185" viewBox="0 0 33.989 43.185">
            <path id="directional-sign"
                  d="M88.19,13.525l-1.672-2.212a1.524,1.524,0,0,0-1.209-.6H74.08V7.92a4.382,4.382,0,1,0-5.141.03v5.756h-6.2a.633.633,0,0,0,0,1.265h6.2v4.976H57.714a.253.253,0,0,1-.2-.1l-1.679-2.252a.285.285,0,0,1,0-.343l1.672-2.181a.253.253,0,0,1,.2-.1h5.029c.349,0-.633-.283-.633-.633s.982-.633.633-.633H57.707a1.526,1.526,0,0,0-1.2.594l-1.672,2.181a1.556,1.556,0,0,0-.012,1.869L56.5,20.6a1.523,1.523,0,0,0,1.216.61H68.939V35.762H66.953a2.322,2.322,0,0,0-2.319,2.319v4.47a.632.632,0,0,0,.633.633H77.917a.632.632,0,0,0,.633-.633v-4.47a2.322,2.322,0,0,0-2.319-2.319H74.08v-4.47a.633.633,0,1,0-1.265,0v4.47H70.2V8.579a4.4,4.4,0,0,0,2.61-.013V31.292c0,.349.283-.633.633-.633s.633.982.633.633V18.134h7.886c.349,0-.633-.283-.633-.633s.982-.633.633-.633H74.08V11.977H85.309a.252.252,0,0,1,.2.1l1.672,2.212a.281.281,0,0,1,0,.342l-1.664,2.142a.249.249,0,0,1-.2.1h-3.35a.633.633,0,0,0,0,1.265h3.35a1.505,1.505,0,0,0,1.2-.586l1.664-2.142A1.549,1.549,0,0,0,88.19,13.525Zm-11.96,23.5a1.055,1.055,0,0,1,1.054,1.054v3.838H65.9V38.082a1.055,1.055,0,0,1,1.054-1.054ZM68.372,4.386a3.117,3.117,0,1,1,3.117,3.121A3.122,3.122,0,0,1,68.372,4.386Z"
                  transform="translate(-54.513)" fill="#6ebd44"/>
        </svg>
    );
};

export default DirectionalSignIcon;
