import React, { useState } from 'react';
import Container from 'components/atoms/container';
import Button from 'components/atoms/button';
import Modal from 'components/atoms/modal';

import ContactPageStyled, { ContactText, Form, LeftSide, RightSide } from './contactPage.styled';
import { TextField } from '../sendCvPage/sendCvPage.styled';

import photo from 'assets/photos/sec3.svg';
import { contactUs } from '../../api/api';
import { E_MAIL, NAME } from '../../config/constants/regexps';
import Textarea from '../../components/atoms/textarea';

const ContactPage = () => {
  const [message, setMessage] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const handleMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };
  const handleSendForm = (event: any) => {
    event.preventDefault();
    if (E_MAIL.test(email) && NAME.test(fullName.trim()) && NAME.test(message.trim())) {
      setModalShow(true);
      const body = {
        name: fullName,
        email: email,
        message: message,
      };
      contactUs(body).then(res => console.log(res));
    } else {
      setError(true);
    }
  };

  return (
    <Container>
      <ContactPageStyled>
        <LeftSide>
          <h1>Contact us</h1>
          <ContactText>
            Let's talk about your idea – fill out the form below and we'll get back to you shortly.
            We can't wait to start working with you!
          </ContactText>
          <h2>Fill out the form</h2>
          <Form action="">
            <TextField
              type="text"
              placeholder="Full name"
              className="name-input"
              value={fullName}
              onChange={e => setFullName(e.target.value)}
              validated={NAME.test(fullName.trim())}
              error={error}
            />
            <TextField
              type="email"
              placeholder="Email address"
              className="email-input"
              value={email}
              onChange={e => setEmail(e.target.value)}
              validated={E_MAIL.test(email)}
              error={error}
            />
            <Textarea
              name="text"
              id="message"
              cols={40}
              rows={5}
              onChange={e => handleMessage(e)}
              placeholder={'Your Message'}
              value={message}
              validated={NAME.test(message.trim())}
              error={error}
            />
            <Button type="submit" onClick={handleSendForm}>
              Send Message
            </Button>
          </Form>
        </LeftSide>
        <RightSide>
          <img src={photo} alt="any" />
        </RightSide>
      </ContactPageStyled>
      <Modal show={modalShow} onHide={() => setModalShow(false)} done />
    </Container>
  );
};

export default ContactPage;
