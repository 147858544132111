import React from 'react';
import SequenceStyled, { Description, Title } from './sequence.styled';
import IconWithBackground from '../iconWithBackground';
import { SequenceProps } from './types';

const Sequence = ({ data }: SequenceProps) => {
  return (
    <SequenceStyled>
      <IconWithBackground hovered size={'lg'}>
        {data.photo}
      </IconWithBackground>
      <Title>{data.title}</Title>
      <Description>{data.text}</Description>
    </SequenceStyled>
  );
};

export default Sequence;
