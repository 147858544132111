import React from 'react';
import { StyledButton } from './button.styled';
import type { ButtonProps } from './types';

const Button = ({
  children,
  outlined,
  onClick,
  type = 'button',
  className,
  size = 'md',
}: ButtonProps) => {
  return (
    <StyledButton
      className={className}
      outlined={outlined}
      onClick={onClick}
      type={type}
      size={size}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
