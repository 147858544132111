import React from 'react';
import ProductItemWrapper, { Description, ImageWrapper, Title } from './productItem.styled';
import { ProductItemProps } from './types';

const ProductItem = ({ itemData }: ProductItemProps) => {
  return (
    <ProductItemWrapper>
      <ImageWrapper>
        <img src={`${process.env.REACT_APP_BASE_API_URL}${itemData.imgUrl}`} alt="any" />
      </ImageWrapper>
      <Title>{itemData.title}</Title>
      <Description>{itemData.description}</Description>
    </ProductItemWrapper>
  );
};

export default ProductItem;
