import React from 'react';
import LoaderStyled, { LoaderInner } from './loader.styled';

const Loader = () => {
  return (
    <LoaderStyled>
      <LoaderInner>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </LoaderInner>
    </LoaderStyled>
  );
};

export default Loader;
