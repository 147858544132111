import React, { useEffect, useState } from 'react';
import ProductsPageWrapper, {
  ButtonWrapper,
  OurProducts,
  PerProductWrapper,
  ProductsWrapper,
  Title,
} from './productsPage.styled';
import Container from '../../components/atoms/container';
import TextWithImage from '../../components/molecules/textWithImage';
import { productsPageData } from './mock';
import ProductItem from '../../components/molecules/productItem';
import Button from '../../components/atoms/button';
import { getAllProducts } from '../../api/api';

const ProductsPage = () => {
  const [productsData, setProductsData] = useState([]);
  useEffect(() => {
    getAllProducts().then(res => setProductsData(res.data));
  }, []);

  return (
    <ProductsPageWrapper>
      <Container>
        <TextWithImage data={productsPageData} withoutButton />
        <OurProducts>
          <Title>Our Products</Title>
          <ProductsWrapper>
            {productsData.map((item, index) => (
              <PerProductWrapper key={index}>
                <ProductItem itemData={item} />
              </PerProductWrapper>
            ))}
          </ProductsWrapper>
          {productsData.length > 8 ? (
            <ButtonWrapper>
              <Button size={'lg'}>Meet the whole jobs</Button>
            </ButtonWrapper>
          ) : (
            ''
          )}
        </OurProducts>
      </Container>
    </ProductsPageWrapper>
  );
};
export default ProductsPage;
