import React from 'react';
import { ITextAreaProps } from './types';
import TextareaStyled from './textarea.styled';

const Textarea = ({
  name,
  id,
  cols,
  rows,
  onChange,
  placeholder,
  value,
  validated,
  error,
}: ITextAreaProps) => {
  // @ts-ignore
  return (
    <TextareaStyled
      name={name}
      id={id}
      cols={cols}
      rows={rows}
      onChange={e => onChange(e)}
      placeholder={placeholder}
      value={value}
      validated={validated}
      // @ts-ignore
      error={error}
    />
  );
};

export default Textarea;
