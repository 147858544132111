import React from 'react';

const RocketIcon = () => {
    return (
        <svg id="fast" xmlns="http://www.w3.org/2000/svg" width="50.793" height="49.306" viewBox="0 0 50.793 49.306">
            <path id="Path_10883" data-name="Path 10883" d="M333.1,88a5.1,5.1,0,1,0,5.1,5.1A5.1,5.1,0,0,0,333.1,88Zm0,8.5a3.4,3.4,0,1,1,3.4-3.4A3.4,3.4,0,0,1,333.1,96.5Z" transform="translate(-295.059 -80.349)" fill="#6ebd44"/>
            <path id="Path_10886" data-name="Path 10886" d="M67.945,16H65.074A16.385,16.385,0,0,0,53.411,20.83a34.488,34.488,0,0,0-4.38,5.371H46.049A9.495,9.495,0,0,0,39.291,29a.85.85,0,0,0,0,1.2l3.4,3.4a.85.85,0,0,0,.6.249h1.942c-.045.13-.091.26-.135.391l-2.409,2.409A90.557,90.557,0,0,0,32.348,49.034a6.8,6.8,0,0,0-10.295,1.908A4.254,4.254,0,0,0,18,52.292l1.275,1.125a2.553,2.553,0,0,1,1.913-.864c.071,0,.142,0,.213.009a6.79,6.79,0,0,0-.213,1.691h1.7a5.1,5.1,0,0,1,8.5-3.8q-.409.614-.809,1.234l-.152.236A6.907,6.907,0,0,1,24.59,55.1v1.7a8.6,8.6,0,0,0,7.264-3.966l.152-.236A88.836,88.836,0,0,1,43.3,38.458L46.341,41.5l-1.949,1.949a9.266,9.266,0,0,0,0,13.1l1.2-1.2a7.508,7.508,0,0,1-2.119-4.142,3.4,3.4,0,0,1,4.856,2.4,7.6,7.6,0,0,0-1.107.351l.638,1.576a5.955,5.955,0,0,1,8.182,5.518h1.7A7.66,7.66,0,0,0,50.093,53.4l-.072,0a5.1,5.1,0,0,0-6.618-4,7.507,7.507,0,0,1,2.191-4.753L50.552,39.7c.131-.044.261-.089.391-.135V41.5a.85.85,0,0,0,.249.6l3.4,3.4a.85.85,0,0,0,1.2,0,9.495,9.495,0,0,0,2.8-6.758V35.763a34.495,34.495,0,0,0,5.371-4.38A16.385,16.385,0,0,0,68.8,19.721V16.85a.85.85,0,0,0-.85-.85Zm-.85,1.7v2.022a14.871,14.871,0,0,1-.515,3.892,5.565,5.565,0,0,1-3.326-1.605l-.467-.467a5.565,5.565,0,0,1-1.605-3.326,14.871,14.871,0,0,1,3.892-.515ZM43.644,32.151l-2.518-2.518A7.8,7.8,0,0,1,46.049,27.9h1.928a34.665,34.665,0,0,0-2.1,4.25Zm.85,5.1L45.843,35.9l3.048,3.048L47.543,40.3Zm12.4,1.494a7.8,7.8,0,0,1-1.732,4.923L52.643,41.15V38.914a34.666,34.666,0,0,0,4.251-2.1Zm5.869-8.565a32.99,32.99,0,0,1-12.442,7.8l-3.5-3.5a32.989,32.989,0,0,1,7.8-12.442,14.762,14.762,0,0,1,4.928-3.267,7.254,7.254,0,0,0,2.043,3.977l.467.467a7.254,7.254,0,0,0,3.977,2.043,14.764,14.764,0,0,1-3.267,4.928Z" transform="translate(-18.002 -15.999)" fill="#6ebd44"/>
            <path id="Path_10887" data-name="Path 10887" d="M31.973,410.842a5.1,5.1,0,0,0-4.782.721l1.021,1.359a3.4,3.4,0,0,1,5.441,2.721h1.7a5.093,5.093,0,0,0-1.9-3.965,4.252,4.252,0,0,1,5.721-1.418l.851-1.472a5.955,5.955,0,0,0-8.057,2.053Z" transform="translate(-26.215 -366.338)" fill="#6ebd44"/>
            <path id="Path_10888" data-name="Path 10888" d="M190.632,400a5.94,5.94,0,0,0-3.967,1.515l1.134,1.267a4.252,4.252,0,0,1,6.842,4.585l1.6.566A5.954,5.954,0,0,0,190.632,400Z" transform="translate(-168.742 -359.195)" fill="#6ebd44"/>
        </svg>

    );
};

export default RocketIcon;
