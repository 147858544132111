import React, { useEffect, useState } from 'react';
import SendCvPageStyled, {
  Form,
  PhoneInputStyled,
  RightSide,
  Row,
  Text,
  TextField,
  UploadFile,
  UploadFileWrapper,
} from './sendCvPage.styled';
import Container from 'components/atoms/container';
import 'react-phone-input-2/lib/style.css';
import IconWithBackground from 'components/atoms/iconWithBackground';
import FileIcon from 'assets/icon/FileIcon';
import Button from 'components/atoms/button';
import PositionCart from 'components/molecules/positionCart';
import Modal from '../../components/atoms/modal';
import { useParams } from 'react-router-dom';
import Loader from '../../components/atoms/loader';
import { CurrentJobProps } from './types';
import { getCareerById, sendCv } from '../../api/api';
import { E_MAIL, NAME, PHONE } from '../../config/constants/regexps';

const SendCvPage = () => {
  const params = useParams();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('374');
  const [file, setFile] = useState<any>(null);
  const [error, setError] = useState(false);
  const [currentJob, setCurrentJob] = useState<CurrentJobProps>({
    _id: '',
    type: '',
    state: '',
    position: '',
    jobDescription: [],
    level: '',
    experience: [],
    techSkills: [],
    softSkills: [],
    appreciated: [],
  });
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    getCareerById(params.id).then(res => setCurrentJob(res.data));
  }, [params.id]);

  const handleUpload = (event: any) => {
    setFile(event.target.files[0]);
  };
  const handleSendForm = (event: any) => {
    event.preventDefault();
    console.log();
    if (E_MAIL.test(email) && NAME.test(fullName) && PHONE.test(phone)) {
      setModalShow(true);
      const formData = new FormData();
      formData.append('name', fullName);
      formData.append('email', email);
      formData.append('phoneNumber', phone);
      formData.append('file', file);
      sendCv(formData).then(res => res);
    } else {
      setError(true);
    }
  };

  if (Object.keys(currentJob).length === 0) {
    return <Loader />;
  }

  return (
    <SendCvPageStyled>
      <Container>
        <Row>
          <Form action="">
            <h1>Full Stack Developer</h1>
            <TextField
              type="text"
              placeholder="Full name"
              className="name-input"
              value={fullName}
              onChange={e => setFullName(e.target.value)}
              validated={NAME.test(fullName)}
              error={error}
            />
            <TextField
              type="email"
              placeholder="Email address"
              className="email-input"
              value={email}
              onChange={e => setEmail(e.target.value)}
              validated={E_MAIL.test(email)}
              error={error}
            />
            <PhoneInputStyled
              country={'am'}
              value={phone}
              onChange={phone => setPhone(phone)}
              validated={PHONE.test(phone)}
              hasError={error}
            />
            <h2>Upload CV</h2>
            <UploadFileWrapper>
              <UploadFile htmlFor="uploadCv">
                <IconWithBackground hovered size={'md'}>
                  <FileIcon />
                </IconWithBackground>
                <Text>
                  <h4>Add File</h4>
                  <h6>{file ? file.name : 'File Name'}</h6>
                </Text>
                <input
                  type="file"
                  id="uploadCv"
                  style={{ display: 'none' }}
                  onChange={handleUpload}
                />
              </UploadFile>
              <Button type="submit" onClick={e => handleSendForm(e)}>
                Send CV
              </Button>
            </UploadFileWrapper>
          </Form>
          <RightSide>
            <PositionCart data={currentJob} onClick={() => console.log('asdasd')} />
          </RightSide>
        </Row>
      </Container>
      <Modal show={modalShow} onHide={() => setModalShow(false)} done={'true'} />
    </SendCvPageStyled>
  );
};

export default SendCvPage;
