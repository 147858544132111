import React from 'react';
import Background from './iconWithBackground.styled';
import { IconWithBackgroundProps } from './types';

const IconWithBackground = ({ children, hovered, size = 'sm' }: IconWithBackgroundProps) => {
  return (
    <Background hovered={hovered} size={size}>
      <div className="green-background" />
      {children}
    </Background>
  );
};

export default IconWithBackground;
