import React from 'react';

const FailedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
      <path
        d="M42.6077 48.0835H7.56032C6.10887 48.0803 4.71778 47.5024 3.69145 46.476C2.66512 45.4497 2.08714 44.0586 2.08398 42.6072V7.55983C2.08714 6.10838 2.66512 4.71729 3.69145 3.69096C4.71778 2.66463 6.10887 2.08665 7.56032 2.0835H34.9408C35.2313 2.0835 35.5099 2.19889 35.7153 2.40429C35.9207 2.60969 36.0361 2.88828 36.0361 3.17876C36.0361 3.46925 35.9207 3.74783 35.7153 3.95323C35.5099 4.15864 35.2313 4.27403 34.9408 4.27403H7.56032C6.68916 4.27498 5.85395 4.62146 5.23795 5.23746C4.62195 5.85347 4.27546 6.68867 4.27452 7.55983V42.6072C4.27546 43.4783 4.62195 44.3135 5.23795 44.9295C5.85395 45.5455 6.68916 45.892 7.56032 45.893H42.6077C43.4788 45.892 44.314 45.5455 44.93 44.9295C45.546 44.3135 45.8925 43.4783 45.8935 42.6072V23.9876C45.8935 23.6972 46.0088 23.4186 46.2142 23.2132C46.4197 23.0078 46.6982 22.8924 46.9887 22.8924C47.2792 22.8924 47.5578 23.0078 47.7632 23.2132C47.9686 23.4186 48.084 23.6972 48.084 23.9876V42.6072C48.0808 44.0586 47.5029 45.4497 46.4765 46.476C45.4502 47.5024 44.0591 48.0803 42.6077 48.0835Z"
        fill="#CB2121"
      />
      <path
        d="M28.4347 25.4982L37.3843 16.5681C37.7763 16.1761 37.9964 15.6445 37.9964 15.0901C37.9964 14.5358 37.7763 14.0042 37.3843 13.6122C36.9924 13.2202 36.4609 13 35.9066 13C35.3524 13 34.8208 13.2202 34.4289 13.6122L25.5 22.5631L16.5711 13.6122C16.1792 13.2202 15.6476 13 15.0934 13C14.5391 13 14.0076 13.2202 13.6157 13.6122C13.2237 14.0042 13.0036 14.5358 13.0036 15.0901C13.0036 15.6445 13.2237 16.1761 13.6157 16.5681L22.5653 25.4982L13.6157 34.4284C13.4206 34.6219 13.2657 34.8521 13.1601 35.1058C13.0544 35.3594 13 35.6315 13 35.9063C13 36.1811 13.0544 36.4532 13.1601 36.7069C13.2657 36.9605 13.4206 37.1907 13.6157 37.3843C13.8091 37.5794 14.0393 37.7342 14.293 37.8399C14.5466 37.9456 14.8186 38 15.0934 38C15.3681 38 15.6402 37.9456 15.8938 37.8399C16.1474 37.7342 16.3776 37.5794 16.5711 37.3843L25.5 28.4333L34.4289 37.3843C34.6224 37.5794 34.8526 37.7342 35.1062 37.8399C35.3598 37.9456 35.6319 38 35.9066 38C36.1814 38 36.4534 37.9456 36.707 37.8399C36.9607 37.7342 37.1909 37.5794 37.3843 37.3843C37.5794 37.1907 37.7343 36.9605 37.8399 36.7069C37.9456 36.4532 38 36.1811 38 35.9063C38 35.6315 37.9456 35.3594 37.8399 35.1058C37.7343 34.8521 37.5794 34.6219 37.3843 34.4284L28.4347 25.4982Z"
        fill="#CB2121"
      />
    </svg>
  );
};

export default FailedIcon;
