import styled from 'styled-components';

const LoaderStyled = styled.div`
  width: 60px;
  height: 60px;
  display: inline-block;
  overflow: hidden;
  background: none;
`;
export const LoaderInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.6);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
  @keyframes rotate {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  div {
    left: 47px;
    top: 16px;
    position: absolute;
    animation: rotate linear 1s infinite;
    background: #6ebd44;
    width: 6px;
    height: 20px;
    border-radius: 3px / 4px;
    transform-origin: 3px 34px;
    box-sizing: content-box;
    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -0.9166666666666666s;
      background: #6ebd44;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -0.8333333333333334s;
      background: #6ebd44;
    }
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.75s;
      background: #6ebd44;
    }
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.6666666666666666s;
      background: #6ebd44;
    }
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.5833333333333334s;
      background: #6ebd44;
    }
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.5s;
      background: #6ebd44;
    }
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.4166666666666667s;
      background: #6ebd44;
    }
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.3333333333333333s;
      background: #6ebd44;
    }
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.25s;
      background: #6ebd44;
    }
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.16666666666666666s;
      background: #6ebd44;
    }
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.08333333333333333s;
      background: #6ebd44;
    }
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
      background: #6ebd44;
    }
  }
`;

export default LoaderStyled;
