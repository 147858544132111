import React from 'react';

const MessageIcon = () => {
    return (
        <svg id="mail_2_" data-name="mail (2)" xmlns="http://www.w3.org/2000/svg" width="21.547" height="17.489" viewBox="0 0 21.547 17.489">
            <path id="Path_10956" data-name="Path 10956" d="M317.7,32.973a2.248,2.248,0,1,0,2.248,2.248A2.248,2.248,0,0,0,317.7,32.973Zm0,3.886a1.637,1.637,0,1,1,1.638-1.638A1.638,1.638,0,0,1,317.7,36.858Zm0,0" transform="translate(-301.691 -31.535)" fill="#6ebd44"/>
            <path id="Path_10957" data-name="Path 10957" d="M16.006.015A5.537,5.537,0,0,0,10.477,5.23H2.05A2.053,2.053,0,0,0,.017,7.018.3.3,0,0,0,0,7.163C0,7.2,0,7.24,0,7.28v8.174a2.04,2.04,0,0,0,.473,1.308.3.3,0,0,0,.1.113A2.044,2.044,0,0,0,2.05,17.5H14.262a2.043,2.043,0,0,0,1.474-.627.3.3,0,0,0,.1-.113,2.041,2.041,0,0,0,.474-1.309v-4.37A5.539,5.539,0,0,0,16.006.015ZM2.05,5.84h8.425A5.515,5.515,0,0,0,11.98,9.358a5.67,5.67,0,0,0,.427.407l-3.431,2.91a1.261,1.261,0,0,1-1.636,0L.638,7A1.442,1.442,0,0,1,2.05,5.84Zm13.957,4.642a4.881,4.881,0,0,1-2.744-.832,2.748,2.748,0,0,1,5.488,0,4.9,4.9,0,0,1-2.744.837ZM2.05,16.893a1.431,1.431,0,0,1-.864-.289l3.683-3.767a.305.305,0,1,0-.436-.427L.785,16.141a1.433,1.433,0,0,1-.175-.687V7.777l6.335,5.365a1.87,1.87,0,0,0,2.425,0l3.532-3a5.47,5.47,0,0,0,2.8.94v4.369a1.43,1.43,0,0,1-.176.689L11.855,12.41a.305.305,0,0,0-.435.428l3.7,3.768a1.432,1.432,0,0,1-.862.287ZM19.313,9.205a3.357,3.357,0,0,0-6.614,0q-.142-.129-.275-.27a4.93,4.93,0,1,1,6.89.267Zm0,0" transform="translate(0 -0.015)" fill="#6ebd44"/>
        </svg>

    );
};

export default MessageIcon;
